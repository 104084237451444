import { setItem } from 'utils/localStorage';
import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from 'services';

interface ResProp {
  data: {
    userId: string;
    planDisplayName: string;
    planName: string;
  };
  headers: any;
  status: number;
}
interface ResOnboardProp {
  data: {
    success: boolean;
    liveMode: boolean;
    redirectToStripeDashboard: boolean;
  };
  headers: any;
  status: number;
}
interface UpdateProfileProp {
  data: {
    success: boolean;
  };
  headers: any;
  status: number;
}



export function* postStripeLoginRequest(action: any) {
  try {
    const res: ResProp = yield Api.postStripeLogin(action.payload);
    if (res.status === 200 && res?.headers && res?.headers?.authorization) {
      const jwttoken = res.headers.authorization;
      const token = jwttoken.replace("Bearer ", "");
      setItem("accessToken", token);
      const finalData: any = {
        ...res.data
      }
      finalData.accessToken = token;
      yield put(actions.postStripeLoginSuccess(finalData));
    }
  } catch (e) {
    yield put(actions.postStripeLoginError());
  }
}

// If redirectToStripeDashboard is true, then integrations page shud be shown.
// If redirectToStripeDashboard is false and liveMode = true, then redirect to stripe live mode dashboard link
// If redirectToStripeDashboard is false and liveMode = false, then redirect to stripe test mode dashboard link


const redirectLink = (liveMode: boolean, redirectToStripeDashboard: boolean) => {

  if (typeof window !== 'undefined' && window.location && redirectToStripeDashboard) {
    window.location.href = "https://app.chargepay.ai/integrations";
  }
  if (typeof window !== 'undefined' && window.location && liveMode && !redirectToStripeDashboard) {
    window.location.href = "https://dashboard.stripe.com/dashboard?open_drawer_app=ai.chargepay.chargeback-manager";
  }
  if (typeof window !== 'undefined' && window.location && !liveMode && !redirectToStripeDashboard) {
    window.location.href = "https://dashboard.stripe.com/test/dashboard?open_drawer_app=ai.chargepay.chargeback-manager";
  }
}
export function* postStripeOnboardLoginRequest(action: any) {
  try {
    const res: ResOnboardProp = yield Api.PostStripeOnboard(action.payload);
    if (res.data.success === false && res?.status === 500) {
      redirectLink(false, false)
    }
    if (res.data.success === true && res?.status !== 500) {
      redirectLink(res?.data?.liveMode, res?.data?.redirectToStripeDashboard)
      yield put(actions.postStripeOnboardSuccess(res.data));
    }
  } catch (e) {
    redirectLink(false, false)
    yield put(actions.postStripeOnboardError());
  }
}

export function* postStripeUpdateBusinessProfileRequest(action: any) {
  try {
    const res: UpdateProfileProp = yield Api.PostStripeUpdateBusiness(action.payload);
    yield put(actions.postStripeUpdateBusinessProfileSuccess(res.data));
  } catch (e) {
    yield put(actions.postStripeUpdateBusinessProfileError());
  }
}
